google.charts.load('current', {packages: ['corechart', 'bar']});
google.charts.setOnLoadCallback(() => {
  $(document).on('turbolinks:load', () => {
    drawColumnChart();
  });

  drawColumnChart();
});

drawColumnChart = () => {
  const elem = document.getElementById('column-chart');
  if (!elem) {
    return;
  }

  const rawData = JSON.parse(elem.getAttribute('data-chart'));

  // vAxis.viewWindowはminとmaxを両方指定しないと設定が有効にならないようなので
  // 最大値 + 10で区切りのよい値をグラフの最大値とする

  // グラフ中の最大値
  const maxValue = Math.max(...rawData.
    slice(1, -1).
    map((record) => record[1]));
  
  // 軸の最大値を計算
  const vAxisMax = 10 * (1 + Math.floor(maxValue / 10));

  const data = google.visualization.arrayToDataTable(rawData);

  const options = {
    gridlines: { count: -1 },
    chartArea: {
      top: 30,
      bottom: 60,
      left: 50,
      right: 20,
    },

    hAxis: {
      title: ''
    },

    vAxis: {
      format: '0',
      viewWindow: {
        min: 0,
        max: vAxisMax
      }
    },

    legend: {
      position: 'bottom',
      alignment: 'center'
    },

    colors: ['#6A6F88']
  };

  const chart = new google.visualization.ColumnChart(elem);

  chart.draw(data, options);
};