$(document).on('turbolinks:load', () => {
  $(document.body).on('change', '#user_person_account', function() {
    console.log('changed', this.checked);

    if (this.checked) {
      $('#user_company_name').val('');
      $('#user_company_name').attr('disabled', 'disabled');
    } else {
      $('#user_company_name').removeAttr('disabled');
    }
  });
});