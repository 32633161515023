import 'easy-autocomplete/dist/jquery.easy-autocomplete';

$(document).on('turbolinks:load', () => {
  // 「削除」ボタンで対象キーワードの行を削除する
  $(document.body).on('click', '.btn-remove-keyword', function(event) {
    event.preventDefault();

    // hiddenのkeyword要素から対象の会社名を削除
    const keywords = $('#competitive_trademark_keyword').
      val().
      split(',').
      filter((keyword) => keyword != $(this).data('keyword'));

    $('#competitive_trademark_keyword').val(keywords.join(','));

    // 行の削除
    $(this).parent().parent().remove();
  });

  // キーワード入力フォームでenterを押したらフォームの送信を停止して、代わりにAPIを叩く
  $('#competitive_trademark_keyword_suggest').on('keydown', function (event) {
    if (event.keyCode === 13) {
      event.preventDefault();

      var evt = $.Event("keyup");
      evt.keyCode = 41; // 41 key is for select

      $('#competitive_trademark_keyword_suggest').trigger(evt);

      return false;
    }
  });

  /**
   * 競合企業名を法人検索APIを利用して検索する
   */
  $('#competitive_trademark_keyword_suggest').easyAutocomplete({
    url: function(phrase) {
      return `/corporates/search?q=${phrase}`;
    },

    theme: 'bootstrap',
    getValue: 'name',

    requestDelay: 500,

    template: {
      type: 'custom',
      method: function(value, item) {
        return `${item.name}<br/><small>${item.address}</small>`;
      }
    },

    // APIコール前に呼ばれるので、ローディング用の画像を表示
    preparePostData: function() {
      $('#loading').removeClass('d-none');
    },

    list: {
      maxNumberOfElements: 100,

      // APIのレスポンスを取得した際に前に呼ばれるので、ローディング用の画像を非表示
      onLoadEvent: function() {
        $('#loading').addClass('d-none');
      },

      onShowListEvent: function() {
        // デザイン微調整
        $('.easy-autocomplete-container').addClass('shown');
      },

      onHideListEvent: function() {
        // デザイン微調整
        $('.easy-autocomplete-container').removeClass('shown');
      },

      onChooseEvent: function(){
        const input = $('#competitive_trademark_keyword_suggest');
        const data = input.getSelectedItemData();
        const index = $('input[name^="competitive_trademark[competitive_trademark_corporates_attributes]"').length + 1

        // キーワード入力フォームの中身をリセット
        input.val('');

        // 行を追加
        $('#keywords')[0].innerHTML += `
        <tr>
          <td class='align-middle'>
            <input type="text"
                value="${data.name}"
                name="competitive_trademark[competitive_trademark_corporates_attributes][${index}][name]"
                id="competitive_trademark_competitive_trademark_corporates_attributes_${index}_name"
                class="text-field-read-only">
            <input type="text"
                value="${data.code}"
                class="text-field-read-only-small"
                name="competitive_trademark[competitive_trademark_corporates_attributes][${index}][number]"
                id="competitive_trademark_competitive_trademark_corporates_attributes_${index}_number">
            </input>
           </td>
          <td class='align-middle text-center' style='width: 80px;'>
          <a href='javascript:void(0)' class='text-muted btn-remove-keyword' data-keyword=''>削除</button>
        </td>
        `;

        // hiddenのkeyword要素に対象の会社名を追加
        const keywords = [
          ...$('#competitive_trademark_keyword').val().split(','),
          data.name
        ].filter((keyword) => keyword != '');

        $('#competitive_trademark_keyword').val(keywords.join(','));

        // 入力しやすいように再度キーワード入力フォームにフォーカスを当てる
        input.focus();
      }
    }
  });
});