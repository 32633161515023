// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").starts
// require("channels")
require('jquery')

import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import "../stylesheets/application"
import './chart'
import './credit_card'
import './keyword_suggest'
import './division_select'
import './registration'
import './payment'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbolinks:load', () => {
  // 商標侵害チェックの種類
  $('input[name="monitoring_trademark[check_type]"]').on('change', function() {
    switch (this.value) {
      case 'code':
        $('div[data="monitoring-trademark-check-type-code"]').removeClass('d-none')
        $('input[name="monitoring_trademark[code]"]').attr('required', 'required');

        $('div[data="monitoring-trademark-check-type-keyword"]').addClass('d-none');
        $('input[name="monitoring_trademark[keyword]"]').removeAttr('required');
        break;
      case 'keyword':
        $('div[data="monitoring-trademark-check-type-code"]').addClass('d-none');
        $('input[name="monitoring_trademark[code]"]').removeAttr('required');

        $('div[data="monitoring-trademark-check-type-keyword"]').removeClass('d-none');
        $('input[name="monitoring_trademark[keyword]"]').attr('required', 'required');
        break;
      default:
        break;
    }
  });

  // 企業動向チェックの種類
  $('input[name="competitive_trademark[check_type]"]').on('change', function() {
    switch (this.value) {
      case 'corporate':
        $('div[data="competitive-trademark-check-type-corporate"]').removeClass('d-none')
        // $('input[name="competitive_trademark[corporate]"]').attr('required', 'required');

        $('div[data="competitive-trademark-check-type-division"]').addClass('d-none');
        // $('input[name="competitive_trademark[keyword]"]').removeAttr('required');
        break;
      case 'division':
        $('div[data="competitive-trademark-check-type-corporate"]').addClass('d-none');
        // $('input[name="competitive_trademark[code]"]').removeAttr('required');

        $('div[data="competitive-trademark-check-type-division"]').removeClass('d-none');
        // $('input[name="competitive_trademark[keyword]"]').attr('required', 'required');
        break;
      default:
        break;
    }
  });

  // パスワードの変更をするかどうか
  $(".change-password").on("change", function() {
    if ($(this).is(":checked")) {
      $(".form-group-password").removeClass("d-none");
    } else {
      $(".form-group-password").addClass("d-none");
    }
  });

  var sideMenuOpenClose = function(){
    $('.side-menu').toggleClass('col-sm-3')
    $('.side-menu').toggleClass('col-sm-1')

    $('.side-menu .fa-angle-double-left').toggleClass('fa-angle-double-right');
    $('.side-menu .fa-angle-double-right').toggleClass('fa-angle-double-left');

    $('.side-menu-contents').toggleClass('col-sm-9')
    $('.side-menu-contents').toggleClass('col-sm-11')

    $('.side-menu .side-menu_text').toggleClass('d-none');
  }

  var sideMenuClose = function() {
    $('.side-menu').addClass('col-sm-1')
    $('.side-menu').removeClass('col-sm-3')

    $('.side-menu .fa-angle-double-left').addClass('fa-angle-double-right');
    $('.side-menu .fa-angle-double-left').removeClass('fa-angle-double-left');

    $('.side-menu-contents').addClass('col-sm-11')
    $('.side-menu-contents').removeClass('col-sm-9')

    $('.side-menu .side-menu_text').addClass('d-none');

    sessionStorage.setItem('side-menu', 'close')
  }

  var sideMenuOpen = function() {
    $('.side-menu').addClass('col-sm-3')
    $('.side-menu').removeClass('col-sm-1')

    $('.side-menu .fa-angle-double-right').addClass('fa-angle-double-left');
    $('.side-menu .fa-angle-double-right').removeClass('fa-angle-double-right');

    $('.side-menu-contents').addClass('col-sm-9')
    $('.side-menu-contents').removeClass('col-sm-11')

    $('.side-menu .side-menu_text').removeClass('d-none');

    sessionStorage.setItem('side-menu', 'open')
  }

  $('#menu-toggle').on('click', function() {
    if(sessionStorage.getItem('side-menu') == null || sessionStorage.getItem('side-menu') == 'open') {
      sideMenuClose();
    } else {
      sideMenuOpen();
    }
  });

  console.log(sessionStorage.getItem('side-menu'))
  if(sessionStorage.getItem('side-menu') == null || sessionStorage.getItem('side-menu') == 'open') {
    sideMenuOpen();
  } else {
    sideMenuClose();
  }
});

