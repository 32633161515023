$(document).on('turbolinks:load', () => {
  // 企業動向チェックのフォーム以外は何もしない
  if (!$('#divisions')) {
    return;
  }

  const filterDivisions = () => {
    const category = $('#competitive_trademark_division_category').val();
    const name = $('#competitive_trademark_division_filter').val();

    $('.form-check-division').each(function() {
      const checkbox = $(this).find("input[type='checkbox']")[0];

      if (category !== '' && category !== $(checkbox).data('division-category')) {
        $(this).addClass('d-none');
        return;
      }

      if (name !== '' && !$(checkbox).data('division-name').match(name)) {
        $(this).addClass('d-none');
        return;
      }

      $(this).removeClass('d-none');
    });
  };

  // 区分の絞り込みフォームでenterを押したらフォームの送信を停止
  $('#competitive_trademark_division_filter').on('keyup compositionend', function(event) {
    filterDivisions();
  });

  $('#competitive_trademark_division_category').on('change', function() {
    filterDivisions();
  });


  // 区分選択
  const filterDivisions2 = () => {
    const category = $('#division_category').val();
    const name = $('#tm_class_division_filter').val();

    $('.form-check-division').each(function() {
      const checkbox = $(this).find("input[type='checkbox']")[0];

      if (category !== '' && category !== $(checkbox).data('division-category-index').toString()) {
        $(this).addClass('d-none');
        $(this).removeClass('d-flex');
        return;
      }

      if (name !== '' && !$(checkbox).data('division-name').match(name)) {
        $(this).addClass('d-none');
        $(this).removeClass('d-flex');
        return;
      }

      $(this).removeClass('d-none');
      $(this).addClass('d-flex');
    });
  };

  // 区分の絞り込みフォームでenterを押したらフォームの送信を停止
  $('#competitive_trademark_division_filter').on('keydown', function(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
  });


  $('#division_category').on('change', function() {
    filterDivisions2();
  });

  // 区分の絞り込みフォームでenterを押したらフォームの送信を停止
  $('#tm_class_division_filter').on('keyup compositionend', function(event) {
    filterDivisions2();
  });

  // 区分のチェックを変更したときの処理
  $('.form-check-input-division').on('change', function() {
    const divisionId = $(this).data('division-id');
    const divisionName = $(this).data('division-name');
    const divisionCategory = $(this).data('division-category');

    if (this.checked) {
      // チェックした場合は行を追加する

      // 行を追加
      $('#divisions')[0].innerHTML += `
      <tr id='selected-division-${divisionId}'>
        <td class='align-middle'>[${divisionCategory}] ${divisionName}</td>
        <td class='align-middle text-center' style='width: 80px;'>
          <a href='javascript:void(0)' class='text-muted btn-remove-division' data-division-id='${divisionId}'>削除</button>
        </td>
      </tr>
      `;
    } else {
      $(`#selected-division-${divisionId}`).remove();
    }
  });

  // 「削除」ボタンでチェックボックスをOFFにする
  $(document.body).on('click', '.btn-remove-division', function(event) {
    event.preventDefault();

    const divisionId = $(this).data('division-id');

    $(`#competitive_trademark_division_ids_${divisionId}`).trigger('click');
  });

  // 追加　

  // 「削除」ボタンでチェックボックスをOFFにする
  $(document.body).on('click', '.remove-division', function(event) {
    event.preventDefault();

    const divisionId = $(this).data('division-id');

    $(`#tm_class_division_ids_${divisionId}`).trigger('click');
  });

  // 区分のチェックを変更したときの処理
  $('.check-division').on('change', function() {
    const divisionId = $(this).data('division-id');
    const divisionName = $(this).data('division-name');

    if (this.checked) {
      // 行を追加
      $('#divisions')[0].innerHTML += `
      <tr id='selected-division-${divisionId}'>
        <td class='align-middle'>${divisionName}</td>
        <td class='align-middle text-center' style='width: 80px;'>
          <a href='javascript:void(0)' class='text-muted remove-division' data-division-id='${divisionId}'>削除</button>
        </td>
      </tr>
      `;
    } else {
      $(`#selected-division-${divisionId}`).remove();
    }
  });

  // $('#division_category').on('change', function() {
  //   $.ajax({
  //     url: '/tm/classes/select_division',
  //     type: 'post',
  //     data: { id: $('#division_category').val() },
  //     dataType:'script'
  //   });
  //   return false;
  // });
});