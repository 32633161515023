$(document).on('turbolinks:load', () => {
  const form = document.getElementById('credit-card-form');

  if (!form) {
    return;
  }

  console.log('load stripe element...');

  const stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY, { apiVersion: '2018-05-21' });
  const elements = stripe.elements({ locale: 'ja' });

  var style = {
    base: {
      // Add your base input styles here. For example:
      fontSize: '16px',
      color: '#32325d',
      lineHeight: '1.429'
    },
  };

  // Create an instance of the card Element.
  var card = elements.create('card', { style, hidePostalCode: true });

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');

  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = '';
      $('#btn-card-submit').prop('disabled', false);
    }
  });

  form.addEventListener('submit', function(event) {
    event.preventDefault();

    stripe.createToken(card).then(function(result) {
      if (result.error) {
        // Inform the customer that there was an error.
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        console.log('result.token', result.token);
        $('#credit_card_token').val(JSON.stringify(result.token));

        form.submit();
      }
    });
  });
});