$(document).on('turbolinks:load', () => {
    var handle_payment_credit = function(){
        $('#payment-credit').prop('checked', true);
        $('#payment-link-key').prop('checked', false);
        $('#payment-invoice').prop('checked', false);

        $('#payment-area1').addClass('active');
        $('#payment-area2').removeClass('active');
        $('#payment-area3').removeClass('active');

        $('#payment-credit-area').removeClass('d-none');
        $('#payment-link-key-area').addClass('d-none');
        $('#payment-invoice-area').addClass('d-none');
    }

    var handle_payment_mf = function(){
        $('#payment-credit').prop('checked', false);
        $('#payment-link-key').prop('checked', true);
        $('#payment-invoice').prop('checked', false);

        $('#payment-area1').removeClass('active');
        $('#payment-area2').addClass('active');
        $('#payment-area3').removeClass('active');


        $('#payment-credit-area').addClass('d-none');
        $('#payment-link-key-area').removeClass('d-none');
        $('#payment-invoice-area').addClass('d-none');
    }

    var handle_payment_invoice = function(){
        $('#payment-credit').prop('checked', false);
        $('#payment-link-key').prop('checked', false);
        $('#payment-invoice').prop('checked', true);

        $('#payment-area1').removeClass('active');
        $('#payment-area2').removeClass('active');
        $('#payment-area3').addClass('active');

        $('#payment-credit-area').addClass('d-none');
        $('#payment-link-key-area').addClass('d-none');
        $('#payment-invoice-area').removeClass('d-none');
    }

    $(document.body).on('click', '#payment-area1', function(event) {
        handle_payment_credit();
    });
    $(document.body).on('click', '#payment-area2', function(event) {
        handle_payment_mf();
    });
    $(document.body).on('click', '#payment-area3', function(event) {
        handle_payment_invoice();
    });

    // init
    handle_payment_credit();
});
